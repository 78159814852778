import { Link } from 'gatsby';
import React from 'react';
import { Button, Container, Grid, Header, Segment } from 'semantic-ui-react';

interface Props {
  heading: string;
  mobile?: boolean;
  subheading: string;
  children?: any;
}

export default () => (
  <Segment inverted vertical style={{ padding: '5em 0em' }}>
    <Container>
      <Grid divided inverted stackable>
        <Grid.Row>
          <Grid.Column width={4}>
            <Header inverted as="h4" content="Copyright" />
            <span>
              Site © by <a href="https://liveloveapp.com">LiveLoveApp</a>, LLC
            </span>
          </Grid.Column>
          <Grid.Column width={12}>
            <Header as="h4" inverted>
              We Can Help!
            </Header>
            <p>Need help with RxJS in your project?</p>
            <Button as={Link} to="/contact" primary size="huge">
              Hire Us
            </Button>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Container>
  </Segment>
);
