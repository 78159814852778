import 'semantic-ui-css/semantic.min.css';

import '../components/styles.scss';

import { withPrefix } from 'gatsby';
import React from 'react';
import { Helmet } from 'react-helmet';

import Footer from './Footer';
import ResponsiveContainer from './ResponsiveContainer';
import { useSiteMetadata } from './SiteMetadata';

require('prismjs/themes/prism-okaidia.css');

interface Props {
  course?: boolean;
  description?: string;
  title: string;
  heading?: string;
  subheading?: string;
  mobile?: boolean;
  children?: any;
}

export default ({
  course,
  description,
  title,
  heading,
  subheading,
  children
}: Props) => {
  const {
    title: defaultTitle,
    description: defaultDescription
  } = useSiteMetadata();

  return (
    <div>
      <Helmet>
        <html lang="en" />
        <title>{title || defaultTitle}</title>
        <meta name="description" content={description || defaultDescription} />
        <link
          rel="icon"
          type="image/png"
          href={`${withPrefix('/')}img/favicon-196x196.png`}
          sizes="196x196"
        />
        <link
          rel="icon"
          type="image/png"
          href={`${withPrefix('/')}img/favicon-128x128.png`}
          sizes="128x128"
        />
        <link
          rel="icon"
          type="image/png"
          href={`${withPrefix('/')}img/favicon-96x96.png`}
          sizes="96x96"
        />
        <link
          rel="icon"
          type="image/png"
          href={`${withPrefix('/')}img/favicon-32x32.png`}
          sizes="32x32"
        />
        <link
          rel="icon"
          type="image/png"
          href={`${withPrefix('/')}img/favicon-16x16.png`}
          sizes="16x16"
        />
        <meta name="theme-color" content="#fff" />

        <meta property="og:type" content="website" />
        <meta property="og:title" content={title} />
        <meta property="og:url" content="/" />
        <meta
          property="og:image"
          content={`${withPrefix('/')}img/og-image.jpg`}
        />
      </Helmet>
      <ResponsiveContainer
        course={course}
        heading={heading}
        subheading={subheading}
      >
        {children}
      </ResponsiveContainer>
      <Footer></Footer>
    </div>
  );
};
