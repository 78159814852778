import { Link } from 'gatsby';
import React, { useState } from 'react';
import {
  Button,
  Container,
  Icon,
  Menu,
  Segment,
  Sidebar,
  Visibility,
} from 'semantic-ui-react';
import styled from 'styled-components';

import HomepageHeading from './HomepageHeading';
import { MediaContextProvider, Media } from './Media';

interface Props {
  course?: boolean;
  heading: string;
  subheading: string;
  children?: any;
}

// const MobileSidebar = styled(({ innerAs, ...rest }) => (
//   <Sidebar as={innerAs} {...rest} />
// ))`
//   display: none;
//
//   @media screen and (max-width: ${Responsive.onlyMobile.maxWidth}px) {
//     display: block;
//   }
// `;
//
// const MobileSegment = styled(Segment)`
//   display: none;
//
//   @media screen and (max-width: ${Responsive.onlyMobile.maxWidth}px) {
//     display: block;
//   }
// `;
//
// const FixedMenu = styled(Menu)`
//   display: none !important;
//
//   &.fixed {
//     display: flex !important;
//   }
//
//   @media screen and (min-width: ${Responsive.onlyTablet.minWidth}px) {
//     display: flex !important;
//   }
// `;

const MenuButton = styled(Button)`
  margin-left: 10px !important;

  &:first-child {
    margin-left: 0 !important;
  }
`;

const TopBar = styled(Segment)`
  padding: 0 !important;

  @media screen and (min-width: 768px) {
    padding: 1em 0em !important;
  }
`;

const DesktopContainer = ({ course, heading, subheading, children }: Props) => {
  const [fixed, setFixed] = useState(false);
  return (
    <Media greaterThan="sm">
      <Visibility
        once={false}
        onBottomPassed={() => setFixed(true)}
        onBottomPassedReverse={() => setFixed(false)}
      >
        <TopBar inverted textAlign="center" vertical>
          <Menu
            fixed={fixed ? 'top' : null}
            inverted={!fixed}
            pointing={!fixed}
            secondary={!fixed}
            size="large"
          >
            <Container>
              <Menu.Item as={Link} to="/" activeClassName="active">
                Home
              </Menu.Item>
              <Menu.Item as={Link} to="/contact" activeClassName="active">
                Hire Us
              </Menu.Item>
              <Menu.Item position="right">
                <MenuButton
                  as={Link}
                  to="/course"
                  className={[
                    'ui',
                    'button',
                    !fixed && 'inverted',
                    !!fixed && 'primary',
                  ]
                    .filter(Boolean)
                    .join(' ')}
                >
                  {course ? 'Table of Contents' : 'Launch Course'}
                </MenuButton>
              </Menu.Item>
            </Container>
          </Menu>
          {!!heading && heading.length > 0 ? (
            <HomepageHeading heading={heading} subheading={subheading} />
          ) : null}
        </TopBar>
      </Visibility>
      {children}
    </Media>
  );
};

const MobileContainer = ({ course, heading, subheading, children }: Props) => {
  const [sidebarOpened, setSidebarOpened] = useState(false);
  return (
    <Media as={Sidebar.Pushable} at="sm">
      <Sidebar.Pushable>
        <Sidebar
          as={Menu}
          animation="overlay"
          inverted
          onHide={() => setSidebarOpened(false)}
          vertical
          visible={sidebarOpened}
        >
          <Menu.Item as={Link} to="/" active>
            Home
          </Menu.Item>
          <Menu.Item as={Link} to="/contact">
            Hire Us
          </Menu.Item>
        </Sidebar>
        <Sidebar.Pusher dimmed={sidebarOpened}>
          <Segment inverted textAlign="center" vertical>
            <Container>
              <Menu inverted pointing secondary size="large">
                <Menu.Item onClick={() => setSidebarOpened(true)}>
                  <Icon name="sidebar" />
                </Menu.Item>
                <Menu.Item position="right">
                  <MenuButton as={Link} to="/course">
                    {course ? 'Table of Contents' : 'Launch Course'}
                  </MenuButton>
                </Menu.Item>
              </Menu>
            </Container>
            {!!heading && heading.length > 0 ? (
              <HomepageHeading heading={heading} subheading={subheading} />
            ) : null}
          </Segment>
          {children}
        </Sidebar.Pusher>
      </Sidebar.Pushable>
    </Media>
  );
};

export default (props: Props) => {
  return (
    <MediaContextProvider>
      <DesktopContainer {...props}>{props.children}</DesktopContainer>
      <MobileContainer {...props}>{props.children}</MobileContainer>
    </MediaContextProvider>
  );
};
