import React from 'react';
import { Container, Header } from 'semantic-ui-react';
import styled from 'styled-components';

interface Props {
  heading: string;
  mobile?: boolean;
  subheading: string;
  children?: any;
}

const Heading = styled(({ innerAs, ...rest }) => (
  <Header as={innerAs} {...rest} />
))<Props>`
  font-size: ${props => (props.mobile ? '2em' : '4em')} !important;
  font-weight: 'normal';
  margin-bottom: 0;
  margin-top: ${props => (props.mobile ? '1.5em' : '3em')} !important;
`;

const Subheading = styled(({ innerAs, ...rest }) => (
  <Header as={innerAs} {...rest} />
))<Props>`
  font-size: ${props => (props.mobile ? '1.5em' : '1.7em')} !important;
  font-weight: 'normal';
  margin-bottom: 100px !important;
  margin-top: ${props => (props.mobile ? '0.5em' : '1.5em')} !important;
  text-align: center !important;
`;

export default ({ heading, mobile, subheading }: Props) => {
  return (
    <Container text>
      <Heading innerAs="h1" content={heading} inverted />
      <Subheading
        innerAs="h2"
        content={subheading}
        className="no-style"
        inverted
      />
    </Container>
  );
};
